
import './App.scss';
import UnityWebViewer from './UnityWebPlayer';
import Header from './components/Header';

function App() {
  return (
    <div className="App">
      
      <UnityWebViewer />
      <Header />
    </div>
  );
}

export default App;
