import "./Header.scss";
import {ReactComponent as PartnershipLogo} from "./../assets/Partnership-in-Education-Logo-Alt.svg";
import {ReactComponent as BibliotechLogo} from "./../assets/BibliotechLogo.svg";
import { Link } from "react-router-dom";

const Header = ({}) => {
  return(
    <div className="header">
      <a href="/"><BibliotechLogo /></a> 
      <div className="row">
        <PartnershipLogo />
        <a target="_blank" href="https://thepartnershipineducation.com"><button >Visit ➔</button></a>
      </div>
      
    </div>
  )
}

export default Header;