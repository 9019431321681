import React, { useCallback, useEffect, useRef, useState } from "react";
import { Unity, useUnityContext } from "react-unity-webgl";
import "./UnityWebPlayer.scss";

const UnityWebViewer = () => {
  const buildPrefix = "/webplayer/Bibliotech";
 const [devicePixelRatio, setDevicePixelRatio] = useState(
   2
 );
  const { unityProvider, sendMessage, addEventListener, removeEventListener } =
    useUnityContext({
      loaderUrl: `${buildPrefix}.loader.js`,
      dataUrl: `${buildPrefix}.data`,
      frameworkUrl: `${buildPrefix}.framework.js`,
      codeUrl: `${buildPrefix}.wasm`,
    });

     useEffect(() => {
      //  const updateDevicePixelRatio = function () {
      //    setDevicePixelRatio(window.devicePixelRatio);
      //  };
      //  // A media matcher which watches for changes in the device pixel ratio.
      //  const mediaMatcher = window.matchMedia(
      //    `screen and (resolution: ${devicePixelRatio}dppx)`
      //  );
      //  // Adding an event listener to the media matcher which will update the
      //  // device pixel ratio of the Unity Application when the device pixel
      //  // ratio changes.
      //  mediaMatcher.addEventListener("change", updateDevicePixelRatio);
      //   console.log("DEVICE PIXEL RATIO", window.devicePixelRatio)

      //  return function () {
      //    // Removing the event listener when the component unmounts.
      //    mediaMatcher.removeEventListener("change", updateDevicePixelRatio);
      //  };

     
      
        
       },
       [devicePixelRatio]
     );



  return (
    <div className="unity-web-player">
      <Unity
        matchWebGLToCanvasSize
        unityProvider={unityProvider}
        style={{ width: "100%", height: "100%" }}
        devicePixelRatio={devicePixelRatio}
      />
    </div>
  );
};

export default UnityWebViewer;
